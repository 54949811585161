import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step crypto key sign`}</strong>{` -- sign a message using an asymmetric key`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step crypto key sign [<file>] --key=<key-file>
[--alg=<algorithm>] [--pss] [--raw] [--password-file=<file>]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step crypto key sign`}</strong>{` generates a signature of the digest of a file or a message
using an asymmetric key.`}</p>
    <p>{`For an RSA key, the resulting signature is either a PKCS #1 v1.5 or PSS
signature. For an (EC)DSA key, it is a DER-serialized, ASN.1 signature
structure.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`file`}</inlineCode>{`
File to sign`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--key`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the private key.`}</p>
    <p><strong parentName="p">{`--alg`}</strong>{`=`}<inlineCode parentName="p">{`algorithm`}</inlineCode>{`
The hash algorithm to use on RSA PKCS #1 1.5 and RSA-PSS signatures.`}</p>
    <p><inlineCode parentName="p">{`algorithm`}</inlineCode>{` must be one of:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`sha1`}</strong>{` (or sha): SHA-1 produces a 160-bit hash value`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`sha224`}</strong>{`: SHA-224 produces a 224-bit hash value`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`sha256`}</strong>{` (default): SHA-256 produces a 256-bit hash value`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`sha384`}</strong>{`: SHA-384 produces a 384-bit hash value`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`sha512`}</strong>{`: SHA-512 produces a 512-bit hash value`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`sha512-224`}</strong>{`: SHA-512/224 uses SHA-512 and truncates the output to 224 bits`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`sha512-256`}</strong>{`: SHA-512/256 uses SHA-512 and truncates the output to 256 bits`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`md5`}</strong>{`: MD5 produces a 128-bit hash value`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`--pss`}</strong>{`
Use RSA-PSS signature scheme.`}</p>
    <p><strong parentName="p">{`--raw`}</strong>{`
Print the raw bytes instead of the base64 format.`}</p>
    <p><strong parentName="p">{`--password-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing passphrase to decrypt the private key.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Sign a file using the default options:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto key sign --key priv.key file.txt
`}</code></pre>
    <p>{`Sign a message using the default options:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ echo "message to be signed" | step crypto key sign --key priv.key
`}</code></pre>
    <p>{`Sign a file using SHA512 as a digest algorithm:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto key sign --key priv.key --alg sha512 file.txt
`}</code></pre>
    <p>{`Sign a file using the PKCS #1 v1.5:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto key sign --key rsa.key file.txt
`}</code></pre>
    <p>{`Sign a file using the RSA-PSS scheme:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto key sign --key rsa.key --pss file.txt
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      